'use strict';

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

(function ($, FUNCTIONS) {
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	// ＜ルート設定対応＞要素が空だったら削除
	var removeElmIfEmpty = function removeElmIfEmpty() {
		$('.js-checkEmptyElm').each(function (idx, val) {
			if (!$(val).text().length) {
				$(val).remove();
			}
		});
	};
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	var removeBlockIfListEmpty = function removeBlockIfListEmpty() {
		if ($('.js-checkEmptyList').length) {
			$('.js-checkEmptyList').each(function (idx, val) {
				if (!$(val).find('li').length) {
					$(val).parents('.js-checkEmptyList-wrap').remove();
				}
			});
		}
	};
	var tabChange = function tabChange() {
		if ($('.js-tabChange-btn').length) {
			var btn = $('.js-tabChange-btn');
			var content = $('.js-tabChange-content');
			btn.eq(0).addClass(a);
			content.eq(0).addClass(a);
			btn.each(function () {
				$(this).on('click', function () {
					var index = btn.index(this);
					btn.removeClass(a);
					$(this).addClass(a);
					content.removeClass(a);
					content.eq(index).addClass(a);
					if ($('.__title').length) {
						$('.__title').matchHeight();
					}
				});
			});
			var urlParam = location.search.substring(1);
			var paramArray = [];
			if (urlParam) {
				var param = urlParam.split('&');
				for (var i = 0; i < param.length; i++) {
					var paramItem = param[i].split('=');
					paramArray[paramItem[0]] = paramItem[1];
				}
			}
			if (paramArray.tab) {
				var num = Number(paramArray.tab) - 1;
				btn.removeClass(a);
				content.removeClass(a);
				btn.eq(num).addClass(a);
				content.eq(num).addClass(a);
				var pos = $('.js-tabChange-btn-pos').offset().top;
				if ($(window).width() < 1024) {
					pos -= 60;
				} else {
					pos -= 90;
				}
				setTimeout(function () {
					$('html,body').stop().animate({ scrollTop: pos }, 400, 'easeOutExpo');
				}, 300);
			}
		}
	};
	var slide = function slide() {
		if ($('.mod-slide01').length) {
			var _$$slick;

			$('.mod-slide01').slick((_$$slick = {
				autoplay: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></a>',
				nextArrow: '<a class="slick-next" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></a>',
				dots: false,
				infinite: true
			}, _defineProperty(_$$slick, 'loop', true), _defineProperty(_$$slick, 'responsive', [{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			}]), _$$slick));
		}
		if ($('.mod-slide02').length) {
			var _$$slick2;

			$('.mod-slide02').slick((_$$slick2 = {
				autoplay: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></a>',
				nextArrow: '<a class="slick-next" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></a>',
				dots: false,
				infinite: true
			}, _defineProperty(_$$slick2, 'loop', true), _defineProperty(_$$slick2, 'responsive', [{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			}]), _$$slick2));
		}
	};
	var moreBtn = function moreBtn() {
		if ($('.js-more-btn').length) {
			$('.js-more-btn').on('click', function () {
				$(this).toggleClass(a);
				$(this).prev('.js-more-content').toggleClass(a);
			});
		}
	};
	var snsBtn = function snsBtn() {
		if ($('.js-sns-btn').length) {
			for (var i = 0; i < $('.js-sns-btn').length; i++) {
				var link = $('.js-sns-btn').eq(i).attr('href').replace('$URL$', location.href).replace('$title$', document.title);
				$('.js-sns-btn').eq(i).attr('href', link);
			}
			$('.js-sns-btn-clip').on('click', function () {
				var element = document.createElement('input');
				element.value = location.href;
				document.body.appendChild(element);
				element.select();
				document.execCommand('copy');
				document.body.removeChild(element);
			});
		}
	};
	var textTrim = function textTrim() {
		if ($('.js-textTrim').length) {
			// 文字数切り捨て
			var $setElm = $('.js-textTrim');
			var cutFigure = void 0,
			    textLength = void 0,
			    textTrimmed = void 0;
			var afterTxt = '…';

			if ($(window).width() < 768) {
				cutFigure = '20';
			} else if ($(window).width() < 1024) {
				cutFigure = '20';
			} else {
				cutFigure = '20';
			}
			$setElm.each(function () {
				textLength = $(this).text().length;
				textTrimmed = $(this).text().substr(0, cutFigure);
				if (cutFigure < textLength) {
					$(this).html(textTrimmed + afterTxt).css({ visibility: 'visible' });
				} else if (cutFigure >= textLength) {
					$(this).css({ visibility: 'visible' });
				}
			});
		}
	};
	var detail_blockLink_modal = function detail_blockLink_modal() {
		if ($('.bclo-modal').length) {
			$('.bclo-modal').fancybox({});
		}
	};
	removeElmIfEmpty();
	removeBlockIfListEmpty();
	tabChange();
	slide();
	moreBtn();
	snsBtn();
	textTrim();
	detail_blockLink_modal();
})(window.jQuery, window.FUNCTIONS);